<template>
   <div id="main-wrapper">
      <!-- <Container/> -->
      <section class="blockElement lightgreybg pb-0 pt-5">
         <div class="container">
            <div class="row align-items-center securityStory">
               <div class="col-md-5 col-lg-4">
                  <img src="/assets/images/security-desktop.webp" alt="Your Security - Our Commitment" title="Your Security - Our Commitment" class="img-fluid d-block securityStoryDesktop">
                  <img src="/assets/images/security-mobile.webp" alt="Your Security - Our Commitment" title="Your Security - Our Commitment" class="img-fluid d-block securityStoryMobile">
               </div>
               <div class="col-md-7 col-lg-8">
                  <div class=" securityStoryContent pt-4 pt-md-0 text-center text-md-end">
                     <h1 class="mb-0">{{contentlist.security_content1}}</h1>
                     <p class="d-block mt-2 mt-xl-4 mb-4 mb-lg-0 mb-0">{{contentlist.security_content2}}</p>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section class="blockElement">
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-12 col-lg-7 text-center">
                  <h2 class="mb-0">{{contentlist.security_content3}}</h2>
                  <p class="mb-0 mt-3 mt-md-4">{{contentlist.security_content4}}</p>
               </div>
            </div>
            <div class="row mt-3">
               <div class="col-12 col-md-4">
                  <div class="securityBox text-center text-md-start mt-4">
                     <div class="greyBgIcon rounded-pill mt-5 d-flex align-items-center justify-content-center lightgreybg m-auto m-md-0">
                        <img src="/assets/images/sophisticated-encryption.webp" alt="Sophisticated Encryption" class="img-fluid">
                     </div>
                     <h5 class="m-0 lh-base pt-4">{{contentlist.security_content5}}</h5>
                     <p class="m-0 lh-base pt-3">{{contentlist.security_content6}}</p>
                  </div>
               </div>
               <div class="col-12 col-md-4">
                  <div class="securityBox text-center text-md-start  mt-4">
                     <div class="greyBgIcon rounded-pill mt-5 d-flex align-items-center justify-content-center lightgreybg m-auto m-md-0">
                        <img src="/assets/images/capital-cold-storage.webp" alt="Cold Storage" class="img-fluid">
                     </div>
                     <h5 class="m-0 lh-base pt-4">{{contentlist.security_content7}}</h5>
                     <p class="m-0 lh-base pt-3">{{contentlist.security_content8}}</p>
                  </div>
               </div>
               <div class="col-12 col-md-4 text-center text-md-start">
                  <div class="securityBox mt-4">
                     <div class="greyBgIcon rounded-pill mt-5 d-flex align-items-center justify-content-center lightgreybg m-auto m-md-0">
                        <img src="/assets/images/auditing-and-testing.webp" alt="Auditing & Testing" class="img-fluid">
                     </div>
                     <h5 class="m-0 lh-base pt-4">{{contentlist.security_content9}}</h5>
                     <p class="m-0 lh-base pt-3">{{contentlist.security_content10}}</p>
                  </div>
               </div>
               <div class="col-12 col-md-4 text-center text-md-start">
                  <div class="securityBox mt-4">
                     <div class="greyBgIcon rounded-pill mt-5 d-flex align-items-center justify-content-center lightgreybg m-auto m-md-0">
                        <img src="/assets/images/principles-privilege.webp" alt="Principles Of Least Privilege" class="img-fluid">
                     </div>
                     <h5 class="m-0 lh-base pt-4">{{contentlist.security_content11}}</h5>
                     <p class="m-0 lh-base pt-3">{{contentlist.security_content12}}</p>
                  </div>
               </div>
               <div class="col-12 col-md-4 text-center text-md-start">
                  <div class="securityBox mt-4">
                     <div class="greyBgIcon rounded-pill mt-5 d-flex align-items-center justify-content-center lightgreybg m-auto m-md-0">
                        <img src="/assets/images/monitoring-transaction.webp" alt="24/7 Monitoring" class="img-fluid">
                     </div>
                     <h5 class="m-0 lh-base pt-4">{{contentlist.security_content13}}</h5>
                     <p class="m-0 lh-base pt-3">{{contentlist.security_content14}}</p>
                  </div>
               </div>
               <div class="col-12 col-md-4 text-center text-md-start">
                  <div class="securityBox mt-4">
                     <div class="greyBgIcon rounded-pill mt-5 d-flex align-items-center justify-content-center lightgreybg m-auto m-md-0">
                        <img src="/assets/images/defense-strategy.webp" alt="Defense-In-Depth Strategy" class="img-fluid">
                     </div>
                     <h5 class="m-0 lh-base pt-4">{{contentlist.security_content15}}</h5>
                     <p class="m-0 lh-base pt-3">{{contentlist.security_content16}}</p>
                  </div>
               </div>
            </div>
            <div class="d-block text-center mt-0 pt-5 mt-md-5 pt-md-5"><a :href="(entityName != '') ? static_vars.merchantPortalLinks[entityName].register : ''" class="themeBtn medium">{{contentlist.register_now_content}}</a></div>
         </div>
      </section>
      <section class="blockElement" data-aos="fade-up" data-aos-duration="2000">
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-12 col-lg-7 text-center">
                  <h2 class="mb-0">{{contentlist.security_content17}}</h2>
                  <p class="mb-0 mt-4">{{contentlist.security_content18}}</p>
               </div>
            </div>
            <div class="row justify-content-center mt-5">
               <div class="col-12 col-lg-8" v-if="Object.keys(faqslist).length > 0">
                  <div class="accordion" id="accordionExample" v-for="value,key in faqslist.dataArr['privacy-&-security']" :key="key" :val="value" >
                     <!-- {{faqslist.dataArr.privacy-&-security}} -->
                     <div class="accordion-item" v-if="key <= 2">
                         
                        <h6 @click="accordianFunc(key)" >
                           <button  type="button" class="accordion-button" :class="(setKey == key) ? 'accordionButtonArrow' : ''">{{value.articleName}} </button>
                        </h6>
                        <div  :class="(setKey == key) ? 'accordianContainerShow' : 'accordianContainerHide'">
                           <div class="accordion-body pt-0">
                              <p class="mb-0" v-html="value.description"></p>
                           </div>
                        </div>
                     </div>
                    <!--  <div class="accordion-item">
                        <h6 class="accordion-header medium" id="headingOne">
                           <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">Why do I need a Two-Factor-Authenticator?</button>
                        </h6>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                           <div class="accordion-body pt-0">
                              <p class="mb-0">Two-factor authentication (2FA) is an extra layer of security for your account that requires you to provide two types of identification to log in. Typically, this involves entering a password, as well as a verification code. 
                                 <br>
                                 We have enabled a 2FA login in all our accounts:
                                 <br>
                              <ul>
                                 <li>
                                    Enhance Security: 2FA provides an extra layer of security, making it more difficult for unauthorized users to gain access to your account.
                                 </li>
                                 <li>
                                    Protect Sensitive Information: 2FA can help to protect this information by requiring an additional layer of authentication before granting access.
                                 </li>
                                 <li>
                                    Prevent Fraud: 2FA can help prevent various types of digital fraud, such as phishing or social engineering attacks, by requiring attackers to have additional authentication factors that are not easily obtainable.
                                 </li>
                                 <li>
                                    Peace of Mind: By using 2FA, you can have greater peace of mind knowing that your accounts are more secure and less likely to be compromised.
                                 </li>
                              </ul>
                              </p>
                           </div>
                        </div>
                     </div>
                     <div class="accordion-item">
                        <h6 class="accordion-header medium" id="headingTwo">
                           <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">How does Capital Wallet secure my digital currencies?</button>
                        </h6>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                           <div class="accordion-body pt-0">
                              <p class="mb-0">We take advanced measures to ensure that your digital assets are safe with us. Among others, we use:</p>
                              <ul>
                                 <li>
                                    encryption, 2FA and cold storage to protect digital currency wallets from unauthorized access.
                                 </li>
                                 <li>
                                    firewalls and intrusion detection systems to prevent external attacks on our systems.
                                 </li>
                                 <li>
                                    Multi-sig authorizations, regular security audits, close transaction monitoring.
                                 </li>
                                 <li>
                                    We are regulated and fully compliant (Austrac ACN 629 943 719).
                                 </li>
                              </ul>
                           </div>
                        </div>
                     </div>
                     <div class="accordion-item">
                        <h6 class="accordion-header medium" id="headingThree">
                           <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">Could you provide more information about Capital Wallet's privacy policy?</button>
                        </h6>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                           <div class="accordion-body pt-0">
                              <p class="mb-0">Access to Capital Wallet Services will require the submission of certain personally identifiable information. Please review Capital Wallet’s Privacy Policy at <a href="/privacy-policy" class="secondarycolor">www.capitalwallet.com/privacy</a> for a summary of Capital Wallet’s guidelines regarding the collection and use of personally identifiable information.</p>
                           </div>
                        </div>
                     </div>
                     <div class="accordion-item">
                        <h6 class="accordion-header medium" id="headingFour">
                           <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">Why did I receive an unexpected IP whitelist email?</button>
                        </h6>
                        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                           <div class="accordion-body pt-0">
                              <p class="mb-0">If you were not expecting to receive an IP whitelist confirmation email, this means that someone was able to use your password and 2-step verification code to begin signing in to your Capital Wallet account. Immediately reset your password to help ensure the security of your account and contact our Support Team.</p>
                           </div>
                        </div>
                     </div> -->
                  </div>
               </div>
            </div>
            <div class="d-flex justify-content-center mt-3 mt-md-5">
               <router-link :to="entityPath+'/help-centre'" class="themeBtn medium">{{contentlist.explore_more_content}}</router-link>
            </div>
         </div>
      </section>
      <!-- <Footer /> -->
      <section class="blockElement primarybg" data-aos="fade-up" data-aos-duration="1800">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center primarybgSection">
            <div class="criptoIcon">
              <span class="left-tp one">
                <img src="/assets/images/onHover-2.webp" alt="Icon" title="Crypto 1" width="77" height="90" class="img-fluid">
              </span>
              <span class="left-tp tow">
                <img src="/assets/images/onHover-1.webp" alt="Icon" title="Crypto 2" width="98" height="98" class="img-fluid">
              </span>
              <span class="left-tp three">
                <img src="/assets/images/onHover-3.webp" alt="Icon" title="Crypto 3" width="56" height="89" class="img-fluid">
              </span>
              <span class="left-tp four">
                <img src="/assets/images/onHover-4.webp" alt="Icon" title="Crypto 4" width="87" height="86" class="img-fluid">
              </span>
            </div>
            <div class="content position-relative">
              <h2 class="whitecolor">{{contentlist.get_started_content}}</h2>
              <p class="whitecolor regular mb-0">{{contentlist.cta_three_content2}}</p>
              <a :href="(entityName != '') ? static_vars.merchantPortalLinks[entityName].register : ''" class="whiteBtn medium mt-4">{{contentlist.register_now_content}}</a>
            </div>
          </div>
        </div>
      </div>
    </section>
   </div>
</template>

<script>
import { commonAllmixins } from '@/plugins/commonAll'   //anamica
   export default {
      mixins: [ commonAllmixins],   //anamica
   data() {
      
      return {
         setKey : 0
      };
   },
   created(){
        this.call_faqs()

    },
    methods:{
      accordianFunc(getKey){
         if(getKey == this.setKey){
            this.setKey = -1
         }else{
            this.setKey = getKey
         }
         
      },
        call_faqs(){
            let sendData = {
                'globalParams':{},
                'localParams':{'parameter':this}
            }
            this._runGetFaqsList(sendData)
        },
        
    }
   };
</script>